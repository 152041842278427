import http from "./index";

export const $login = params => {
	const data = http.post("students/Students/login", params);
	return data;
};

export const $emailBack = params => {
	const data = http.post("students/Students/emailBack", params);
	return data;
};