<!-- 重制密码 -->
<template>
	<div class="resetPassword-page">
		<div class="content">
			<el-form ref="form" status-icon :model="form" :rules="rules" label-width="80px" hide-required-asterisk>
				<div class="title">忘记密码:</div>
				<el-form-item label="收件邮箱：" prop="email">
					<el-input autocomplete="off" v-model.trim="form.email"></el-input>
				</el-form-item>
				<el-form-item label="图形验证：" prop="validator">
					<div class="input-wrap">
						<el-input autocomplete="off" v-model.trim="form.validator"></el-input>
						<div id="picyzm"></div>
					</div>
				</el-form-item>

				<el-form-item class="btn-wrap">
					<el-button type="primary" @click="onSubmit">确定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import { $emailBack } from "../services/login";
import "../utils/gVerify";
export default {
	name: "ResetPassword",
	data() {
		var validateCode = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入验证码"));
			} else {
				if (this.verifyCode) {
					let resValid = this.verifyCode.validate(value);
					if (!resValid) {
						callback(new Error("验证码不正确"));
					} else {
						callback();
					}
				} else {
					callback(new Error("系统错误"));
				}
			}
		};
		return {
			form: {
				email: "",
				validator: "",
			},
			rules: {
				email: [
					{ required: true, message: "请输入邮箱地址", trigger: "blur" },
					{ type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] },
				],
				validator: [{ validator: validateCode, trigger: "blur" }],
			},
			verifyCode: null,
		};
	},
	components: {},

	computed: {},

	created() {},

	mounted() {
		// eslint-disable-next-line no-undef
		this.verifyCode = new GVerify({
			id: "picyzm",
			type: "blend",
		});
	},

	methods: {
		onSubmit() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					const data = await $emailBack({ email: this.form.email });
					if (data.code === 1) {
						this.$alert(data.msg, "", {
							confirmButtonText: "确定",
							center: true,
						});
					}
				}
			});
		},
	},
};
</script>

<style scoped lang="less">
.content {
	width: 1200px;
	margin: 0 auto;
	padding: 100px 0;

	.el-form {
		width: 460px;
		margin: 0 auto;
		.title {
			font-size: 20px;
			padding-left: 15px;
			margin-bottom: 40px;
		}
		.input-wrap {
			display: flex;
			.el-input {
				height: 40px;
			}
			#picyzm {
				margin-left: 10px;
			}
		}
	}
}
.btn-wrap {
	display: flex;
	justify-content: center;
	margin-top: 40px;
	.el-button {
		width: 180px;
	}
}
</style>
